import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginLayout from "../Layouts/LoginLayout";
import MasterLayout from "../Layouts/MasterLayout";
import LandingPage from "../Pages/LandingPage";
import LoginPage from "../Pages/Login";
function RoutesList() {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route index element={<LandingPage />} />
      </Route>
      <Route path="/login" element={<LoginLayout />}>
        <Route index element={<LoginPage />} />
      </Route>
      <Route path="/register" element={<LoginLayout />}>
        <Route index element={<>Register</>} />
      </Route>
      {/* <Route path="about" element={<About />} /> */}
    </Routes>
  );
}

export default RoutesList;
