import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";

function PopupContent() {
  return (
    <div className="data-selection-popup">
      <div className="data-selection-popup-content">
        <div className="selection-title">
          <span>Data Selection</span>
        </div>
        <div className="selection-body">
          <div className="">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label={
                  <span className="selection-body-label">
                    Industrial Village
                  </span>
                }
              />
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label={
                  <span className="selection-body-label">
                    Industrial District
                  </span>
                }
              />
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label={
                  <span className="selection-body-label">
                    Special Econonmic Zone
                  </span>
                }
              />
            </FormGroup>
          </div>
          <div className="">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label={<span className="selection-body-label">Approved</span>}
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label={
                    <span className="selection-body-label">
                      Under Construction
                    </span>
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupContent;
