import React from "react";
import NepalGov from "../../Assets/images/nepalGOV_low.png";
import LoginBox from "./LoginBox";

function LoginPage() {
  return (
    <div className="loginPage">
      <div className="login-cover">
        <div className="login-cover-content">
          <div className="cover-image">
            <img src={NepalGov} alt="Nepal Gov" />
          </div>
          <span className="cover-title-1">Government of Nepal</span>
          <span className="cover-title-2">
            Ministry of Industry, Commerce and Supplies
          </span>
        </div>
      </div>
      <LoginBox />
    </div>
  );
}

export default LoginPage;
