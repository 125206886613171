import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";

function Boundary({ setSelectGeoDataId }) {
  return (
    <div className="data-selection-popup">
      <div className="data-selection-popup-content">
        <div className="selection-title">
          <span>Boundary Selection</span>
        </div>
        <div className="selection-body">
          <div className="">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="0"
                name="radio-buttons-group"
                onChange={(e) => {
                  setSelectGeoDataId(parseInt(e.target.value));
                }}
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label={<span className="selection-body-label">Province</span>}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={<span className="selection-body-label">District</span>}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label={
                    <span className="selection-body-label">Local level</span>
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Boundary;
