import React, { useState } from "react";

const SvgNumber = ({ num }) => {
  const [activeMarker, setActiveMarker] = useState(false);
  const [hoverMarker, setHoverMarker] = useState(false);
  // const onMouseEnterFn = () => {
  //   setHoverMarker(true);
  // };
  // const onMouseLeaveFn = () => {
  //   setHoverMarker(false);
  // };
  const onMouseEnterFn = () => {
    setHoverMarker(true);
  };
  const onMouseLeaveFn = () => {
    setHoverMarker(false);
  };
  return (
    <div>
      <div
        class="circle"
        // onMouseEnter={onMouseEnterFn}
        // onMouseLeave={onMouseLeaveFn}
        key={num}
      >
        <span>{num}</span>
      </div>
    </div>
  );
};
function CustomMarker({ lat, lng, text, setCenterPos, setSelectedMarkerId }) {
  return (
    <div
      style={{ width: "2rem" }}
      onClick={() => {
        // setCenterPos([lat, lng]);
        setSelectedMarkerId(text);
      }}
    >
      <SvgNumber num={text} />
    </div>
  );
}

export default CustomMarker;
