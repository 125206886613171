import React from "react";
import NoticeBox from "../NoticeBox";

function NoticeList() {
  return (
    <>
      <div className="notice-list">
        <div className="notice-heading">
          <span className="notice-header-name">Latest Notices</span>
          <span className="notice-header-view-all">View All Notices</span>
        </div>
        <div className="notice-list-flex">
          {[0, 1, 2, 3]?.map((ele) => {
            return <NoticeBox />;
          })}
        </div>
      </div>
    </>
  );
}

export default NoticeList;
