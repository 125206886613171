import { Search } from "@mui/icons-material";
import { TextField } from "@mui/material";
import React from "react";

function SearchComponent() {
  return (
    <div className="search-popup">
      <div className="search-field">
        <TextField
          id="search-bar"
          className="text"
          onInput={(e) => {
            // setSearchQuery(e.target.value);
          }}
          //   label="Enter a city name"
          variant="outlined"
          placeholder="Search Here..."
          fullWidth
          size="small"
        />
        <span className="search-icon">
          <Search />
        </span>
      </div>
    </div>
  );
}

export default SearchComponent;
