import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../../Components/NavBar";

function MasterLayout() {
  return (
    <div className="master-layout">
      <NavBar />

      <Outlet />
    </div>
  );
}

export default MasterLayout;
