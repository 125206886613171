import React from "react";
import LeafletMapComponent from "../../Components/LeafletMapComponent";
import MapComponent from "../../Components/MapComponent";
import NoticeList from "../../Components/Notices/NoticeList";

function LandingPage() {
  return (
    <div>
      <LeafletMapComponent />
      <NoticeList />
    </div>
  );
}

export default LandingPage;
