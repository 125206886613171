import React, { createRef, useMemo, useState } from "react";
import GoogleMapReact from "google-map-react";
import CustomMarker from "../CustomMarker";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import L from "leaflet";
import {
  MapContainer,
  Marker,
  TileLayer,
  GeoJSON,
  LayersControl,
  Popup,
} from "react-leaflet";
import Topojson from "../Topojson";
import provinceGeoData from "../../Assets/province.json";
import districtGeoData from "../../Assets/district.json";
import localGeoData from "../../Assets/local.json";
import CustomPopup from "../CustomPopup";
import LPopup from "react-leaflet-editable-popup";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import SearchComponent from "./SearchComponent";
import { Settings, SettingsOutlined } from "@mui/icons-material";
import PopupContent from "./PopupContent";
import Boundary from "./Boundary";
const { BaseLayer } = LayersControl;
const AnyReactComponent = ({ text }) => (
  <div style={{ width: "5rem", background: "green" }}>{text}</div>
);

const LoadingContainer = (props) => <div>Fancy loading container!</div>;

const GeoData = [
  {
    id: 1,
    data: provinceGeoData,
  },
  {
    id: 2,
    data: districtGeoData,
  },
  {
    id: 3,
    data: localGeoData,
  },
];
const LeafletMapComponent = ({}) => {
  const [centerPos, setCenterPos] = useState(null);
  const [settingBtn, setsettingBtn] = useState(true);
  const [currentPopup, setCurrentPopup] = useState(false);
  const [selectGeoDataId, setSelectGeoDataId] = useState(0);
  const [selectedMarkerId, setSelectedMarkerId] = useState(null);

  const defaultProps = {
    center: {
      lat: 27.677,
      lng: 85.321,
    },
    zoom: 8,
  };
  const markerData = [
    {
      id: 1,
      lat: 27.7172,
      lng: 85.324,
      title: "Kathmandu",
      state: "Bagmati",
      district: "Kathmandu",
    },
    {
      id: 2,
      lat: 27.6588,
      lng: 85.3247,
      title: "Lalitpur",
      state: "Bagmati",
      district: "Lalitpur",
    },
    {
      id: 3,
      lat: 27.671,
      lng: 85.4298,
      title: "Bhaktapur",
      state: "Bagmati",
      district: "Bhaktapur",
    },
  ];

  useMemo(() => {
    const newPopupData = markerData?.find(
      (ele) => ele.id === parseInt(selectedMarkerId)
    );
    setCurrentPopup(newPopupData);
  }, [selectedMarkerId]);

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "80vh", minHeight: "38rem" }}>
      <MapContainer
        center={[defaultProps.center.lat, defaultProps.center.lng]}
        zoom={7}
        scrollWheelZoom={true}
        style={{ height: "100%", borderRadius: "1rem" }}
        minZoom={7}
        maxBounds={[
          // [32.02496, 78.532716],
          // //north east
          // [25.118865, 89],
          [33.02496, 77.532716],
          //north east
          [24.118865, 98],
        ]}
      >
        <div className="right-selection">
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", right: "3rem" }}>
              <span
                className={`icon-block-1 ${settingBtn ? "active" : ""}`}
                onClick={() => {
                  setsettingBtn(!settingBtn);
                }}
              >
                <Settings />
              </span>
              <span
                className={`icon-block-2 ${settingBtn ? "" : "active"}`}
                onClick={() => {
                  setsettingBtn(!settingBtn);
                }}
              >
                <SettingsOutlined />
              </span>
            </div>
          </div>
          <div
            className={`popup-contents ${settingBtn ? "" : "active"}`}
            style={{ marginTop: "3rem" }}
          >
            <SearchComponent />

            <PopupContent />
            <Boundary setSelectGeoDataId={setSelectGeoDataId} />
          </div>
        </div>
        <LayersControl position="bottomleft">
          {/* <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          /> */}

          {/* <ReactLeafletGoogleLayer
            apiKey="AIzaSyD5PhsvkuboYGsd2mGiQxMyTfeqOrYYNm8"
            // type={"satellite"}
          /> */}
          <BaseLayer checked name="Google Maps">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="Satellite">
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              maxZoom={20}
              subdomains={["mt1", "mt2", "mt3"]}
            />
          </BaseLayer>
        </LayersControl>
        {markerData?.map((ele) => {
          const Red_MARKER = `data:image/svg+xml;utf8,${encodeURIComponent(`<?xml version="1.0" encoding="iso-8859-1"?>
           <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
           viewBox="0 0 500 500">
             <g id="UrTavla">
               <circle style="stroke-miterlimit:10;" cx="250" cy="250" r="200"
               stroke="black" stroke-width="1" fill="#2460b9"
               >
               </circle>
               <text x="50%" y="50%" text-anchor="middle" fill="#fff" font-size="245" font-weight="600"  dy=".3em">${ele.id}</text>
             </g>
           </svg>
           `)}`;
          const BoatIcon = L.icon({
            iconUrl: Red_MARKER,
            iconSize: [40, 40],
            iconAnchor: [12, 12],
            popupAnchor: [0, 0],
          });
          const popupRef = createRef();

          return (
            <Marker
              position={[ele?.lat, ele?.lng]}
              icon={BoatIcon}
              key={ele.id}
              riseOnHover={true}
            >
              <Popup ref={popupRef} open={currentPopup}>
                <CustomPopup
                  lat={ele?.lat}
                  lng={ele?.lng}
                  currentPopup={ele}
                  setSelectedMarkerId={setSelectedMarkerId}
                  setCurrentPopup={setCurrentPopup}
                  popupRef={popupRef}
                />
              </Popup>
            </Marker>
          );
        })}

        {selectGeoDataId === 0 && <GeoJSON data={GeoData[0]?.data} />}
        {selectGeoDataId === 1 && <GeoJSON data={GeoData[1]?.data} />}
        {selectGeoDataId === 2 && <GeoJSON data={GeoData[2]?.data} />}
      </MapContainer>
    </div>
  );
};

export default LeafletMapComponent;
