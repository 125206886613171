import React from "react";
import Tabs from "../Tabs";
import { Close } from "@mui/icons-material";

function CustomPopup({
  setSelectedMarkerId,
  currentPopup,
  setCurrentPopup,
  popupRef,
}) {
  return (
    <div
      className="popup-box"
      style={{
        position: "absolute",
        bottom: ".1rem",
        left: "3rem",
        zIndex: 11,
      }}
    >
      <div className="popup-header">
        <span>{currentPopup?.title}</span>
        <span>
          <Close
            width={30}
            height={30}
            onClick={() => {
              popupRef.current._closeButton.click();
            }}
          />
        </span>
      </div>
      <div className="popup-body">
        <Tabs currentPopup={currentPopup} />
      </div>
    </div>
  );
}

export default CustomPopup;
