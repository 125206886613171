import { KeyboardArrowDown, Person } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import NepalGov from "../../Assets/images/nepalGOV_low.png";

function useOutsideAlerter(ref, setProfileDropdown) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setProfileDropdown(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function NavBar() {
  const [profileDropdown, setProfileDropdown] = useState(false);
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  useOutsideAlerter(wrapperRef, setProfileDropdown);
  return (
    <div className="navBar">
      <div className="navBar-left">
        <div className="navBar-image">
          <img src={NepalGov} alt="nepal gob" width={80} />
        </div>
        <div className="navBar-content">
          <span className="logo-title-1">Government of Nepal</span>
          <span className="logo-title-2">
            Ministry of Industry, Commerce and Supplies
          </span>
        </div>
      </div>
      <div className="navBar-right">
        <div className="navBar-right-heading">
          <span className="profile-type">Admin</span>
          <span>|</span>
          <span
            className="profile-icon"
            ref={wrapperRef}
            onClick={() => {
              setProfileDropdown(!profileDropdown);
            }}
          >
            <div>
              <Person />
              <KeyboardArrowDown />
            </div>
          </span>
        </div>
        <div className={`profile-dropdown ${profileDropdown ? "active" : ""}`}>
          <ul className="profile-dropdown-menu">
            <li
              onClick={() => {
                navigate("login");
              }}
            >
              Login
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
