import React, { useState } from "react";

function Tabs({ currentPopup }) {
  const [list, setList] = useState([
    {
      title: "General",
      content: (
        <table>
          <tr>
            <td className="row-head">State</td>
            <td className="row-content">{currentPopup?.state}</td>
          </tr>
          <tr>
            <td className="row-head">District</td>
            <td className="row-content">{currentPopup?.district}</td>
          </tr>
        </table>
      ),
    },
    { title: "Location", content: <>Location</> },
    { title: "Gallery", content: <>Gallery</> },
    { title: "Report", content: <>Report</> },
  ]);
  const [activeEle, setActiveEle] = useState(0);
  return (
    <>
      <div className="popup-body-tabs">
        {list?.map((ele, i) => {
          return (
            <>
              <div
                className={`popup-body-tabs-tab`}
                onClick={() => {
                  setActiveEle(i);
                }}
              >
                <div className={`tab-btn ${activeEle === i ? "active" : ""}`}>
                  <span className="tab-title">{ele.title}</span>
                  <div className="tab-bottom" />
                </div>
              </div>
            </>
          );
        })}
      </div>
      {list?.[activeEle] && (
        <div className="popup-body-content">
          <div className="popup-body-details">{list[activeEle]?.content}</div>
        </div>
      )}
    </>
  );
}

export default Tabs;
