import RoutesList from "./Router";
import "./SCSS/app.scss";
function App() {
  return (
    <div className="mainpage">
      <RoutesList />
    </div>
  );
}

export default App;
