import React from "react";

function NoticeBox() {
  return (
    <div className="notice-box">
      <div className="notice-content">
        <div className="notice-image"></div>
        <div className="notice-detail">
          <span className="notice-detail-title">
            Current Status of Supply of Essential...
          </span>
          <span className="notice-detail-subtitle">
            <span>Category: Notices</span>
            <span>Published Date: 18 June, 2022</span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default NoticeBox;
